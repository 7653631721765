import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import { val }  from '../components/cssvalue'
import { media } from '../components/style-utils';

// STYLE
const BlogPlans = props => {
  const SectionDetailPlan = styled.section`
    background: ${val.BG};
    padding: 30px;
    margin-top: 50px;
    ${media.sp`
      padding: 7vw 0;
    `}
    > .-inner {
      max-width: ${val.width_pc};
      margin: 0 auto;
    }
    > .title {
      text-align: center;
      margin: 0 auto;
      ${media.sp`
        font-size: 5vw;
        margin: 0 1vw;
      `}
    }
    > p {
      text-align: center;
    }
  `
  const PanelWrapPrice = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 0 30px 0;
  ${media.sp`
    padding-bottom: 14vw;
  `}
  > section {
    background: ${val.WHITE};
    margin-top: 20px;
    box-sizing: border-box;
    padding: 14px;
    &.-mid {
      width: 49%;
      ${media.sp`
        width: 91%;
      `}
    }
    ${media.sp`
      width: 91%;
      margin-left: auto;
      margin-right: auto;
      }
    `}
  }
  > .-inner {
    ${media.sp`
      margin: 0 5vw;
    `}
  }
  `;
  const SectionArticlePrice = styled.section`
  `;
  const TitlePrice = styled.div`
  font-family: "Dosis",sans-serif;
  font-weight: 700;
  font-size: 18px;
  padding: 8px 10px 8px 16px;
  letter-spacing: .18em;
  background: ${val.BG3};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  ${media.sp`
    font-size: 4vw;
    padding: 2vw 2.4vw 2vw 4vw;
  `}
  .-price {
    background: ${val.BUTTON};
    color: white;
    font-size: 23px;
    padding: 0 12px 0 19px;
    ${media.sp`
      font-size: 5vw;
      padding: 0 3vw 0 4vw;
    `}
  }
  `;
  const AreaBtn = styled.div`
    text-align: center;
    a {
      color: #fff;
    }
  `;
  const AreaLink = styled.div`
    display :flex;
    flex-wrap: wrap;
    justify-content: space-around;
    > p {
      padding: 0 15px;
    }
    a {
      color: #fff;
      ${media.sp`
        width: 70%;
        margin-bottom: 10px;
        text-align: center;
      `}
    }
  `;
  return (
  <div>
    <SectionDetailPlan>
      <h2 className="title">ホームページ作成 各プラン概要</h2>
      <p><Link to="/web/">詳しくはプラン詳細ページをご確認ください</Link></p>
      <PanelWrapPrice className="-inner">
        <SectionArticlePrice className="-mid">
          <div className="-inner">
              <TitlePrice className="title">
                <span>ご相談</span>
                <span className="-price">￥0</span>
              </TitlePrice>
              <p className="-p">
                <strong>ご相談は無料です。</strong><br / >
                ホームページやデザインに関することならなんでもお気軽にご相談ください。
                </p>
          </div>
        </SectionArticlePrice>
        <SectionArticlePrice className="-mid">
          <div className="box">
            <TitlePrice className="title">
              <span>具体的なアドバイス</span>
              <span className="-price">￥0〜</span>
            </TitlePrice>
            <p className="-p">
              とにかくできるだけお金をかけずにホームページが欲しい場合には、<strong>他社様の無料・格安のホームページサービス</strong>もあります。
            </p>
          </div>
        </SectionArticlePrice>
        <SectionArticlePrice className="-mid">
          <div className="-inner">
            <TitlePrice className="title">
              <span>代行プラン</span>
              <span className="-price">￥55,000〜</span>
            </TitlePrice>
            <p className="-p">
              外部ホームページサービスやネットショッピングサービスを利用したホームページ作成の選定および設定代行サービスです。
            </p>
          </div>
        </SectionArticlePrice>
        <SectionArticlePrice className="-mid">
          <div className="-inner">
            <TitlePrice className="title">
              <span>CMSプラン</span>
              <span className="-price">￥275,000〜</span>
            </TitlePrice>
            <p className="-p">
              <strong>デザインにはこだわらず、安くて自分でも更新ができる中規模のホームページ作成</strong>に最適なプランです。
            </p>
          </div>
        </SectionArticlePrice>
        <SectionArticlePrice className="-mid">
          <div className="-inner">
            <TitlePrice className="title">
              <span>デザインプラン</span>
              <span className="-price">￥330,000〜</span>
            </TitlePrice>
            <p className="-p">
              <strong>独自デザインの小規模なホームページ</strong>が欲しいという方にオススメ<br />
              ある程度独自にデザインをするオリジナルホームページのプランです。
            </p>

          </div>
        </SectionArticlePrice>
        <SectionArticlePrice className="-mid">
          <div className="-inner">
            <TitlePrice className="title">
              <span>ビジネスプラン</span>
              <span className="-price">￥550,000〜</span>
            </TitlePrice>
            <p className="-p">
              通常のホームページが欲しいという方にオススメ<br />
              <strong>独自にデザインをする特注ホームページ</strong>となります。<br />
              CMS等を利用して<strong>ご自分でホームページの更新をしたり記事を追加</strong>したりできます。
            </p>
          </div>
        </SectionArticlePrice>
        <SectionArticlePrice className="-mid">
          <div className="-inner">
            <TitlePrice className="title">
              <span>プレミアムプラン</span>
              <span className="-price">￥1,650,000〜</span>
            </TitlePrice>
            <p className="-p">
              <strong>本格的なホームページ</strong>が欲しいという企業や団体にオススメのプランです。
            </p>
          </div>
        </SectionArticlePrice>
        <SectionArticlePrice className="-mid">
          <div className="-inner">
            <TitlePrice className="title">
              <span>エンタープライズプラン</span>
              <span className="-price">￥3,300,000〜</span>
            </TitlePrice>
            <p className="-p">
              <strong>競合に負けない独自のホームページ</strong>が欲しいという企業向けのプランです。
            </p>
          </div>
        </SectionArticlePrice>
      </PanelWrapPrice>
      <AreaBtn>
        <Link to="/web/" className="btn en btn-arrow"  activeClassName="active">
          <span className="text">ホームページ作成 プランと価格 詳細</span>
          <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
        </Link>
      </AreaBtn>
    </SectionDetailPlan>
    <AreaLink>
      <p>ホームページ作成のほかに、ホームページの運用やデザインもお任せください。<br />
      詳しくは下記詳細ページにてご確認ください。
      </p>
      <Link to="/operate/" className="btn en btn-arrow"  activeClassName="active">
        <span className="text">ホームページ運用 詳細</span>
        <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
      </Link>
      <Link to="/design/" className="btn en btn-arrow"  activeClassName="active">
        <span className="text">デザイン 詳細</span>
        <svg className="icon icon-arrow"><use xlinkHref="#arrow"></use></svg>
      </Link>
    </AreaLink>
  </div>
  )
}

export default BlogPlans