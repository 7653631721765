import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Tags from "../components/tags"
import styled from "styled-components"
import { val }  from '../components/cssvalue'
import { media } from '../components/style-utils';
import BlogPlans from "../components/blogPlans"


export const TagStyle = styled.div`
.tag {
  .-tag {
    display: inline-block;
  }
  .tagLink {
    margin: 0 10px 0 0;
    padding: 2px 10px;
    background: ${val.BG};
    display: inline-flex;
    font-size: 12px;
    color: ${val.BLACK};
    text-decoration: none;
    transition: background .2s;
    i {
      font-style: normal;
    }
  }
  a:hover {
      background: ${val.FOCUS};
    }
  }
}
`;
const PostWrap = styled.div`
  max-width: 767px;
  margin: 0 auto;
  a {
    color: ${val.BLACK};
  }
`;
const PostBody = styled.div`
  ${media.sp`
    margin: 0 5vw;
  `}
  h2 {
    margin-top: 2em;
    margin-bottom: .5em;
    ${media.sp`
      font-size: 5vw;
    `}
  }
  h3 {
    margin-top: 1.5em;
    margin-bottom: .3em;
  }
  h4 {
    margin-top: 1.2em;
    margin-bottom: .1em;
    + p {
      margin-top: .2em;
    }
  }
  pre {
    white-space: pre-wrap;
  }
  ul,
  ol {
    list-style-type: none;
    > li {
      text-indent: -1em;
      margin-left: -2em;
      font-size: smaller;
      margin-top: .5em;
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;
const Header = styled.header`
  padding: 40px 0 10px;
  ${media.sp`
    padding: 9vw 0 2.4vw;
    margin: 0 5vw;
  `}
  h1 {
    margin: 0 0 20px;
    ${media.sp`
      margin: 0 0 4.5vw;
      line-height: 1.5;
      font-size: 5.5vw;
    `}
  }
  time {
    text-align: right;
    display: block;
    font-size: 12px;
    ${media.sp`
      width: 40vw;
      font-size: 2vw;
    `}
  }
  .tag {
    text-align: right;
    ${media.sp`
      text-align: left;
    `}
  }
`;
const ArticleInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.sp`
    align-items: baseline;
  `}
`;
const BlogPostNav = styled.nav`
  max-width: 767px;
  margin: 50px auto 0;
  padding-top: 40px;
  padding-bottom: 50px;
  border-top: 1px solid ${val.BG};
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.sp`
    flex-wrap: wrap;
  `}
  > div {
    width: 33%;
    ${media.sp`
      width: 100%;
    `}
    &:nth-child(1) {
      margin-bottom: 5px;
      margin-right: 5vw;
    }
    &:nth-child(2) {
      text-align: center;
      margin-bottom: 5px;
    }
    &:nth-child(3) {
      text-align: right;
      margin-left: 5vw;
    }
  }
  .btn {
    padding: 5px 10px;
  }
`;
const BlogPostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  let blockPlans = ''
  if(post.frontmatter.addcontent === 'plans') {
    blockPlans = <BlogPlans />
    //blockPlans = `
    //`
  }
  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <PostWrap>
        <Header>
          <h1>{post.frontmatter.title}</h1>
          <ArticleInfo>
            <TagStyle>
              <Tags tags={post.frontmatter.tags} />
            </TagStyle>
            <time>{post.frontmatter.date}</time>
          </ArticleInfo>
        </Header>
        <PostBody
          dangerouslySetInnerHTML={{ __html: post.html }}
        />

{blockPlans}


      </PostWrap>
      <BlogPostNav>
        <div>
            {previous && (
              <Link to={`/blog${previous.fields.slug}`} rel="prev" className="btn">
                ← {previous.frontmatter.title}
              </Link>
            )}
        </div>
        <div>
            <Link to="/blog/" className="btn btn-arrow" style={{textAlign: 'center'}}>
              ブログ・お知らせ<br />記事一覧
            </Link>
        </div>
        <div>
            {next && (
              <Link to={`/blog${next.fields.slug}`} rel="next" className="btn">
                {next.frontmatter.title} →
              </Link>
            )}
        </div>
      </BlogPostNav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY年MM月DD日")
        description
        tags
        addcontent
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
