import React from 'react'
import { Link } from 'gatsby'
import styled from "styled-components"
import { val }  from '../components/cssvalue'
// STYLE
const Tag = props => {
  return (
    <div className="tag">
      {props.tags.map((tag, index) => {
        return (
          <div className="-tag">
          {props.tags.nolink ? <span className="tagLink"><i>{tag}</i>{props.tags.nolink}</span> : <Link to={`/tags/${tag}/`} key={index} className="tagLink"><i>{tag}</i>{props.tags.nolink}</Link>
            }
          </div>
        )
      })}
    </div>
  )
}

export default Tag